import removedUser from 'Assets/images/removed-user.svg';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useConversationStore } from 'Modules/conversation/index.store';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List, ListItemProps, Loader, Popup } from 'semantic-ui-react';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import { resolveConversationPath } from 'Utils/routeNav';
import ActivityListItem from '../ActivityListItem';
import { UserAvatar } from '../shared/UserAvatar';
import { ChannelMemberItemProps } from './interfaces';

/**
 * List item display of a `Participant` in a `Channel`.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelMembers
 */

export function ChannelMemberItem({
  getPerson,
  makeCall,
  participant,
  contact,
  uiStore,
  from,
  loggedInPersonId,
  conversationId,
  conversation,
  person,
  participantStore,
  setShowPersonDetails,
}: ChannelMemberItemProps): JSX.Element {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [faded, setFaded] = React.useState<boolean>(false);

  // Keep optOutPhoneNumbersInfo here so the component updates when it changes
  const { optOutPhoneNumbersInfo, getOptOutPhoneNumberInfo } =
    useConversationStore();

  const location = useLocation();
  const navigate = useNavigate();

  const onMouseOver = () => setHovered(true);
  const onMouseOut = () => setHovered(false);

  const _renderDefaultAvatar = () => (
    <div className="list-item-ih-image flex-zero">
      <div className="removed-user">
        R U
        <img src={removedUser} className="presence-badge" />
      </div>
    </div>
  );

  const openInfoDetails = (
    id: number | string,
    type: string,
    from: 'personInfo' | 'groupInfo'
  ) => {
    if (from === 'personInfo') {
      setShowPersonDetails(id, type);
      conversation.setChannelInfoDetails('0', 'new');
    } else {
      conversation.setChannelInfoDetails(id.toString(), type);
      setShowPersonDetails(null, type);
    }
    uiStore.setActiveConversationIdPinnMess('');
  };

  const openChat = (personId) => () => {
    conversation
      .loadOrCreateConversationWithPost(personId)
      .then((resp) => navigate(`/chat/conversations/${resp.data.id}/menu`));
  };

  const openChatWithNumber = (phone) => () => {
    conversation
      .loadOrCreateConversationWithPost(0, phone)
      .then((resp) => navigate(`/chat/conversations/${resp.data.id}/menu`));
  };

  const updateRoutes = (path) => {
    const newPath = resolveConversationPath(location.pathname, path);
    navigate(newPath);
  };

  const personPbo = participant.personId
    ? getPerson(participant.personId)
    : null;
  const contactPbo =
    participant.personId === 0 || !participant.personId
      ? contact.loadContactByPhoneNumber(participant.phone)
      : null;
  const listItemProps: ListItemProps = {
    // stretched: true, (TODO: Prop seems to no longer apply? RP 2019-08-29)
    className: cx('list-item-ih flex-row channel-member-item', {
      faded,
      hovered,
    }),
  };
  if (
    (personPbo && personPbo.state === 'fulfilled') ||
    (contactPbo && contactPbo.state === 'fulfilled')
  ) {
    listItemProps.onMouseOver = onMouseOver;
    listItemProps.onMouseOut = onMouseOut;
    listItemProps.style = { cursor: 'pointer' };
  }

  const externalContact = contactPbo
    ? person.getExtrContactByPhoneNumber(participant.phone)
    : null;

  return (
    <List.Item {...listItemProps}>
      {personPbo ? (
        personPbo.case({
          pending: () => <Loader active indeterminate size="mini" />,
          rejected: () => (
            <>
              {_renderDefaultAvatar()}
              <List.Content className="list-item-ih-content flex-grow-shrink">
                <span className="color-removed">
                  Removed User #{participant.personId}
                </span>
              </List.Content>
            </>
          ),
          fulfilled: (personResp) => {
            const presence = uiStore.selectPersonPresenceStatus(
              personResp?.data?.id
            );
            return (
              <>
                <div className="list-item-ih-image flex-grow">
                  {
                    // After portal side make profile pic work, uncomment this one
                    // personResp.data.DisplayAvatar ?
                    // <Image className='profile-pic' src={`${personResp.data.DisplayAvatar}`} size='mini' />
                    // :
                    <UserAvatar
                      name={personResp.data.DisplayName}
                      selectUnreadCounts={
                        uiStore.selectConversationUnreadCounts
                      }
                      presence={presence}
                      key={`channel-member-avatar-${personResp?.data?.id}-${presence?.state}`}
                    />
                  }
                </div>
                <List.Content
                  className="list-item-ih-content flex-grow-shrink status-group-info"
                  id="group-members"
                  onClick={openChat(personResp.data.id)}
                >
                  <div className="group-members-info-container">
                    <p data-private className="group-members-name">
                      {personResp.data.DisplayName}
                    </p>
                    {personResp.data.phoneNumber &&
                      getOptOutPhoneNumberInfo(
                        personResp?.data?.phoneNumber
                      ) === true && (
                        <p className="group-members-opted-out">Opted-out</p>
                      )}
                  </div>
                  <Popup
                    inverted
                    trigger={
                      <span className="message-status">
                        {uiStore.selectPersonMessageStatus(personResp.data.id)
                          ?.title || ''}
                      </span>
                    }
                    content={
                      uiStore.selectPersonMessageStatus(personResp.data.id)
                        ?.message || ''
                    }
                    position={'top right'}
                  />
                </List.Content>
                <div className={cx('list-item-ih-hover-buttons', { hovered })}>
                  {from === 'group-info' ? (
                    <ActivityListItem
                      conversation={conversation?.CurrentConversation}
                      conversationId={conversation ? conversationId : '0'}
                      isCurrentConversation={false}
                      loggedInAccountId={person.loggedInAccountId}
                      loggedInPersonId={loggedInPersonId}
                      makeCall={makeCall}
                      key={conversationId}
                      loadPresenceIfMissing={uiStore.loadPresenceIfMissing}
                      loggedInUserActiveConferenceConversation={
                        conversation.LoggedInUserActiveConferenceConversation
                      }
                      participant={participantStore}
                      participantsPbo={participantStore.selectParticipantsByConversationId(
                        getCurrentConversationId()
                      )}
                      postConferenceByConversationId={
                        conversation.postConferenceByConversationId
                      }
                      selectParticipantPersonInfo={
                        person.loadPersonByIdGetIfMissingGet
                      }
                      selectPersonPresenceStatus={
                        uiStore.selectPersonPresenceStatus
                      }
                      updateRoute={updateRoutes}
                      selectUnreadCounts={
                        uiStore.selectConversationUnreadCounts
                      }
                      setConversationAndTotalUnreadCount={
                        uiStore.setConversationAndTotalUnreadCount
                      }
                      updateMyLastReadMessage={
                        participantStore.updateMyLastReadMessage
                      }
                      personStore={person}
                      conversationStore={conversation}
                      from={from}
                      handleDetails={openInfoDetails}
                      currentPersonId={personResp.data.id}
                      setShowPersonDetails={person.setShowPersonDetails}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          },
        })
      ) : contactPbo && contactPbo.case ? (
        contactPbo.case({
          pending: () => <Loader active indeterminate size="mini" />,
          rejected: () => (
            <>
              {_renderDefaultAvatar()}
              <List.Content className="list-item-ih-content flex-grow-shrink">
                <span className="color-removed">
                  Removed User #{participant.phone}
                </span>
              </List.Content>
            </>
          ),
          fulfilled: (contactResp) => {
            return (
              <>
                <div className="list-item-ih-image flex-grow">
                  {
                    // After portal side make profile pic work, uncomment this one
                    // personResp.data.DisplayAvatar ?
                    // <Image className='profile-pic' src={`${personResp.data.DisplayAvatar}`} size='mini' />
                    // :
                    <UserAvatar
                      name={null}
                      hideInitials
                      selectUnreadCounts={
                        uiStore.selectConversationUnreadCounts
                      }
                    />
                  }
                </div>
                <List.Content
                  className="list-item-ih-content flex-grow-shrink status-group-info"
                  id="group-members"
                  onClick={openChatWithNumber(contactResp.data.phoneNumber)}
                >
                  <div className="group-members-info-container">
                    <p data-private className="group-members-name">
                      {externalContact?.DisplayName() ||
                        contactResp.data.DisplayName}
                    </p>
                    {contactResp.data.phoneNumber &&
                      getOptOutPhoneNumberInfo(
                        contactResp?.data?.phoneNumber ||
                          externalContact?.phoneNumbers?.[0]?.number
                      ) === true && (
                        <p className="group-members-opted-out">Opted-out</p>
                      )}
                  </div>
                </List.Content>
                <Popup
                  inverted
                  trigger={
                    <span className="message-status">
                      {uiStore.selectPersonMessageStatus(
                        parseFloat(contactResp.data.phoneNumber)
                      )?.title || ''}
                    </span>
                  }
                  content={
                    uiStore.selectPersonMessageStatus(
                      parseFloat(contactResp.data.phoneNumber)
                    )?.message || ''
                  }
                  position={'top right'}
                />
                <div className={cx('list-item-ih-hover-buttons', { hovered })}>
                  {from === 'group-info' ? (
                    <ActivityListItem
                      conversation={conversation?.CurrentConversation}
                      conversationId={conversation ? conversationId : '0'}
                      isCurrentConversation={false}
                      loggedInAccountId={person.loggedInAccountId}
                      loggedInPersonId={loggedInPersonId}
                      makeCall={makeCall}
                      key={conversationId}
                      loadPresenceIfMissing={uiStore.loadPresenceIfMissing}
                      loggedInUserActiveConferenceConversation={
                        conversation.LoggedInUserActiveConferenceConversation
                      }
                      participant={participantStore}
                      participantsPbo={participantStore.selectParticipantsByConversationId(
                        getCurrentConversationId()
                      )}
                      postConferenceByConversationId={
                        conversation.postConferenceByConversationId
                      }
                      selectParticipantPersonInfo={
                        person.loadPersonByIdGetIfMissingGet
                      }
                      selectPersonPresenceStatus={
                        uiStore.selectPersonPresenceStatus
                      }
                      updateRoute={updateRoutes}
                      selectUnreadCounts={
                        uiStore.selectConversationUnreadCounts
                      }
                      setConversationAndTotalUnreadCount={
                        uiStore.setConversationAndTotalUnreadCount
                      }
                      updateMyLastReadMessage={
                        participantStore.updateMyLastReadMessage
                      }
                      personStore={person}
                      conversationStore={conversation}
                      from={from}
                      handleDetails={openInfoDetails}
                      currentPersonId={contactResp.data.phoneNumber}
                      setShowPersonDetails={person.setShowPersonDetails}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          },
        })
      ) : (
        <span className="person-pbo-missing">...</span>
      )}
    </List.Item>
  );
}

export default observer(ChannelMemberItem);
