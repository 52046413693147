import { FormField } from 'Components/shared/forms/FormField';
import type { InputFieldProps } from 'Components/shared/forms/InputField/index.types';
import React, { forwardRef, useState } from 'react';
import { theme } from '~/styles/theme/Theme';
import { Styled } from './index.styles';

/**
 * `InputField` is a compound component that wraps a styled text input field within a form field.
 * It uses the Compound Component pattern to expose additional components as static properties.
 *
 * @param {InputFieldProps} props - The properties that define the `InputField` component. These properties are spread onto the `FormField` and `Styled.TextInput` components.
 * @param {React.Ref} ref - A ref that can be used to access the underlying `Styled.TextInput` component.
 *
 * @returns {React.ReactElement} Returns a `FormField` component that contains a `Styled.TextInput` component.
 */
export const Trigger = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      action,
      border = 'all',
      disabled,
      disabledLabel,
      errorMessage,
      hasFixedFooter,
      innerSpacing,
      label,
      labelSize,
      labelWeight,
      hint,
      message,
      leftNode,
      rightNode,
      testID,
      value,
      ...props
    }: InputFieldProps,
    ref
  ): React.ReactElement => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isMouseFocused, setIsMouseFocused] = useState<boolean>(false);

    const componentTestId = testID ? `${testID}-selectTrigger` : 'selectTrigger';
    const computedBorderVariation = Array.isArray(border) ? border : [border];

    return (
      <FormField
        label={label}
        action={action}
        leftNode={leftNode}
        rightNode={rightNode}
        innerSpacing={innerSpacing}
        labelSize={labelSize}
        labelWeight={labelWeight}
        errorMessage={errorMessage}
        hasFixedFooter={hasFixedFooter}
        disabledLabel={disabledLabel}
        hint={hint}
        message={message}
        testID={componentTestId}
      >
        <Styled.Trigger
          ref={ref}
          data-testid={componentTestId}
          $placeholderTextColor={theme?.colors?.gray}
          $leftNode={!!leftNode}
          $rightNode={!!rightNode}
          disabled={disabled}
          $border={computedBorderVariation}
          $isFocused={isFocused}
          $isMouseFocused={isMouseFocused}
          {...props}
          onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => {
            setIsMouseFocused(true);

            if (props.onMouseDown) {
              props.onMouseDown(e);
            }
            }}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(true);

            if (props.onFocus) {
              props.onFocus(e);
            }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(false);

            if (props.onBlur) {
              props.onBlur(e);
            }

            if (e.nativeEvent instanceof FocusEvent) {
              setIsMouseFocused(false);
            }
          }}
        >
          {value}
        </Styled.Trigger>
      </FormField>
    );
  }
);

Trigger.displayName = 'Trigger';
