import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import API from '~/api';
import { IMessageModel } from '../../models/MessageModel';
import PersonModel from '../../models/PersonModel';

export const QUERY_KEY_FETCH_MESSAGE = 'message.fetchMessage';

export const fetchMessage = async (
  conversationId: string,
  messageId: string
): Promise<IMessageModel | undefined> => {
  if (!conversationId || !messageId) {
    return undefined;
  }

  const { data } = await API.get<PersonModel>(
    API_ENDPOINTS.ConversationMessageById(conversationId, messageId)
  );

  return data;
};

export const useFetchMessage = (conversationId: string, messageId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_MESSAGE, conversationId, messageId],
    queryFn: async () => fetchMessage(conversationId, messageId),
  });

export const useMessage = () => ({
  useFetchMessage,
});
