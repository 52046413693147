import { Icon } from 'Components/shared/Icon';
import { IconButton } from 'Components/shared/IconButton';
import styled, { keyframes, css } from 'styled-components';

const revealHideAnimationBanner = keyframes`
  from {
    transform: translateY(30px);
    visibility: hidden;
  }
  to {
    transform: translateY(0);
    visibility: unset;
  }
`;

const revealHideAnimationBannerBottom = keyframes`
  from {
    visibility: hidden;
  }
  to {
    visibility: unset;
  }
`;

const Wrapper = styled.div<{ isVisible: boolean; canAnimate: boolean }>`
  position: absolute;
  top: -30px;

  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.lightGray2};

  display: flex;
  align-items: center;
  height: 30px;

  visibility: hidden;
  transform: translateY(30px);

  ${({ canAnimate, isVisible }) =>
    canAnimate &&
    css`
      animation: ${isVisible
        ? css`
            ${revealHideAnimationBanner} 300ms linear forwards
          `
        : css`
            ${revealHideAnimationBanner} 300ms linear reverse forwards
          `};
    `}
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.sidebar} !important;
`;

const CloseIconButton = styled(IconButton)`
  .ui.button {
    color: ${({ theme }) => theme.colors.sidebar} !important;
  }
`;

const Text = styled.div`
  flex: 1;
  font-size: 11px;
  line-height: ${({ theme }) => theme.sizes.xsmall};
  color: ${({ theme }) => theme.colors.sidebar};
`;
const InputBannerBottom = styled.div<{
  isVisible: boolean;
  canAnimate: boolean;
}>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.lightGray2};

  visibility: hidden;

  ${({ canAnimate, isVisible }) =>
    canAnimate &&
    css`
      animation: ${isVisible
        ? css`
            ${revealHideAnimationBannerBottom} 300ms linear forwards
          `
        : css`
            ${revealHideAnimationBannerBottom} 300ms linear reverse forwards
          `};
    `}
`;

export const Styled = {
  Icon: StyledIcon,
  CloseIconButton,
  Wrapper,
  Text,
  InputBannerBottom,
};
