import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import API from '~/api';

export const QUERY_KEYS = {
  voicemails: ['voicemails/count'],
};
export const useBadgeCounters = () => {
  const voicemailQuery = useQuery<{ total: number }>({
    queryKey: QUERY_KEYS.voicemails,
    queryFn: async () => {
      const response = await API.get<{ total: number }>(
        API_ENDPOINTS.UnreadVoicemailCount
      );
      return response?.data;
    },
  });

  return {
    voicemailQuery,
  };
};
