import { Button } from 'Components/shared/Button';
import { Heading } from 'Components/shared/Heading';
import { Modal as BaseModal } from 'Components/shared/Modal';
import styled from 'styled-components';

const Modal = styled(BaseModal)`
  padding: 70px 46px 56px 37px;
  max-width: 770px;
  // due legacy styles
  border-radius: 4px !important;
  box-shadow: 1px 3px 3px #0003, 1px 3px 15px 2px #0003 !important;
  border: none !important;
  font: 1rem/18px 'Open Sans', sans-serif !important;

  @media only screen and (min-width: 768px) {
    & {
      width: 70.4%;
    }
  }
  @media only screen and (min-width: 992px) {
    & {
      width: 680px;
    }
  }
  @media only screen and (min-width: 1200px) {
    & {
      width: 720px;
    }
  }
`;

const TextContainer = styled(Heading)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text} !important;
  text-align: center;
`;

const CloseButtonContainer = styled.div`
  position: absolute !important;
  top: 32px !important;
  right: 32px !important;
`;

const ActionButtonsContainer = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
`;

const CancelButton = styled(Button)`
  border: 1px solid #0099ad !important;
  margin-left: 24px !important;
`;

const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  background: #fff;
`;

export const Styled = {
  Modal,
  TextContainer,
  CloseButtonContainer,
  ActionButtonsContainer,
  CancelButton,
  Content,
};
