import styled, { css } from 'styled-components';
import { RectangularButtonProps } from './index.types';

const BUTTON_VARIANTS = {
  primary: css`
    color: ${({ theme }) => theme.colors.base};
    background-color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-color: ${({ theme }) => theme.colors.linkHover};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.linkClick};
    }
  `,
  secondary: css`
    outline: 1px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.base};
    &:hover {
      background-color: ${({ theme }) => theme.colors.border};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.mediumGray};
    }
  `,
};

const Button = styled.button<{
  $variant: RectangularButtonProps['variant'];
  $active: boolean;
}>`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  cursor: pointer;
  ${({ $variant }) => BUTTON_VARIANTS[$variant]}
  ${({ $active, $variant, theme }) =>
    $active &&
    css`
      background: ${$variant === 'primary'
        ? theme.colors.linkClick
        : theme.colors.mediumGray};
    `}

  &:disabled {
    opacity: 0.45;
    pointer-events: none;
  }
`;

export const Styled = {
  Button,
};
