import type {
  FormFieldLabelSize,
  FormFieldLabelWeight,
  FormFieldProps,
} from './index.types';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const Header = styled.div<{ $innerSpacing: FormFieldProps['innerSpacing']}>`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: ${({ $innerSpacing }) => $innerSpacing}px;
  `;

const Label = styled.label<{
  $labelSize?: FormFieldLabelSize;
  $labelWeight?: FormFieldLabelWeight;
  disabled?: boolean;
}>`
  font-size: ${({ $labelSize }) => {
    switch ($labelSize) {
      case 'md':
        return '16px';
      case 'sm':
      default:
        return '14px';
    }
  }};
  line-height: 19px;
  font-weight: ${({ $labelWeight }) => $labelWeight || 'normal'};
  color: ${({ theme }) => theme.colors.text};
  flex: 1;
  margin-bottom: 0;
  ${({ disabled }) => (disabled ? 'opacity: 0.5;' : 'opacity: 1;')}
  `;

const Action = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  `;

const Hint = styled.div`
  width: 100%;
  grid-column: 1 / 2;
  grid-row: 2;
  margin-top: 5px;
  font-size: 13px;
  line-height: 19px;
`;

const Content = styled.div<Pick<FormFieldProps, 'hasBorder'>>`
  width: 100%;
  position: relative;
`;

const LeftNode = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  z-index: 1;
`;

const RightNode = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
`;

const Footer = styled.div`
  width: 100%;
  min-height: 20px;
  padding-top: 5px;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const ErrorMessage = styled.p`
  flex: 1;
  font-size: 11px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.error};
`;


const Message = styled.div`
  width: 100%;
  font-size: 13px;
  line-height: 19px;
  margin-top: 5px;
`;

export const Styled = {
  Wrapper,
  Header,
  Label,
  Action,
  Hint,
  Content,
  RightNode,
  LeftNode,
  Footer,
  ErrorContainer,
  ErrorMessage,
  Message,
};
