import React, { useEffect, useState } from 'react';
import { Styled } from './index.styles';
import { BannerProperties, MessageBannerProps } from './index.types';

export const MessageBanner: React.FC<MessageBannerProps> = ({
  icon,
  text = '',
  isVisible = false,
  canClose = true,
}) => {
  const [canAnimate, setCanAnimate] = useState(false);

  /**
   * These have to be states (instead of using the props directly) to avoid text/icon/etc disappearing when closing.
   */
  const [isBannerVisible, setIsBannerVisible] = useState(isVisible);
  const [bannerProperties, setBannerProperties] = useState<BannerProperties>(
    {}
  );

  useEffect(() => {
    if (isVisible) {
      setBannerProperties({ canClose, icon, text });
    }
  }, [isVisible, canClose, icon, text]);

  useEffect(() => {
    setIsBannerVisible(isVisible);
    isVisible && setCanAnimate(true);
  }, [isVisible]);

  const handleClose = () => {
    setIsBannerVisible(false);
  };

  return (
    <>
      <Styled.Wrapper
        isVisible={isBannerVisible}
        canAnimate={canAnimate}
        aria-hidden={!isBannerVisible}
        key={isBannerVisible ? 'visible-banner-top' : 'hidden-banner-top'}
        role="banner"
      >
        {bannerProperties.icon && (
          <Styled.Icon icon={bannerProperties.icon} size="tiny" />
        )}
        <Styled.Text>{bannerProperties.text}</Styled.Text>
        {bannerProperties.canClose && (
          <Styled.CloseIconButton
            variant="text"
            icon="cross"
            size="mini"
            onClick={handleClose}
            testid="MessageBanner-close"
            compact
          />
        )}
      </Styled.Wrapper>
      <Styled.InputBannerBottom
        isVisible={isBannerVisible}
        canAnimate={canAnimate}
        key={isBannerVisible ? 'visible-banner-bottom' : 'hidden-banner-bottom'}
      />
    </>
  );
};
