import PrintMessageHeader from 'Components/ContextContentItemsList/PrintMessageHeader';
import { MobXProviderContext, observer } from 'mobx-react';
import { fetchOptOutStatus } from 'Modules/conversation/index.requests';
import { useConversationStore } from 'Modules/conversation/index.store';
import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { isNullOrUndefined } from 'util';

export const AdHocGroup: React.FC = observer(() => {
  const {
    conversation: { adHocGroupParticipants, changeConvTopic },
    notification,
    person,
    ui,
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const topic = changeConvTopic(adHocGroupParticipants);

  React.useEffect(() => {
    void (async () => {
      if (adHocGroupParticipants?.length) {
        try {
          const participantsWithoutOptoutStatus = adHocGroupParticipants
            .filter(
              (participant) =>
                participant.phoneNumber &&
                isNullOrUndefined(
                  useConversationStore
                    .getState()
                    .getOptOutPhoneNumberInfo(participant.phoneNumber)
                )
            )
            .map((participant) => participant.phoneNumber);

          await fetchOptOutStatus(participantsWithoutOptoutStatus);
        } catch (error) {
          notification.addAxiosErrorNotification(
            error,
            'Error fetching opt out status'
          );
        }
      }
    })();
  }, [adHocGroupParticipants]);

  return (
    <>
      <div id="context-header" className="flex-row flex-zero">
        <Header as="h2" className="context-header-display valign-middle">
          <Header.Content className="channel-header-topic" content={topic} />
        </Header>
      </div>
      <div id="context-content" className="flex-column flex-grow-shrink">
        <PrintMessageHeader
          extrContact={null}
          hasMessages={false}
          persons={adHocGroupParticipants}
          ui={ui}
          loggedInPerson={person.loggedInPersonId}
        />
      </div>
    </>
  );
});

export default AdHocGroup;
