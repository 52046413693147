import type { FormFieldProps } from './index.types';
import { Icon } from 'Components/shared/Icon';
import React from 'react';
import { Styled } from './index.styles';

/**
 * Represents a generic form field component.
 *
 * This component is designed to be a reusable building block for form inputs.
 * It can be used to create various types of inputs such as text, password, email, etc., by specifying the appropriate type. It includes features for displaying labels, error messages, and handling changes.
 *
 * @param {FormFieldProps} props - The properties for the FormField component.
 *
 * @returns {React.ReactElement} The FormField component.
 */
export const FormField = ({
  action,
  children,
  disabledLabel,
  errorMessage,
  hasBorder = false,
  hasFixedFooter = false,
  innerSpacing = 10,
  label,
  labelSize,
  labelWeight,
  leftNode,
  hint,
  message,
  rightNode,
  testID,
  id,
  ...props
}: FormFieldProps): React.ReactElement => {
  const componentTestId = testID ? `${testID}-formField` : 'formField';

  return (
    <Styled.Wrapper data-testid={componentTestId} {...props}>
      <Styled.Header $innerSpacing={innerSpacing}>
        {/* Slot for rendering form inputs label */}
        {label && (
          <Styled.Label
            $labelSize={labelSize}
            $labelWeight={labelWeight}
            data-testid={`${componentTestId}Label`}
            disabled={disabledLabel}
            htmlFor={
              (React.isValidElement<{ id?: string }>(children) &&
                children.props.id) ||
              ''
            }
          >
            {label}
          </Styled.Label>
        )}
        {/* Slot for rendering form inputs action */}
        {action && (
          <Styled.Action data-testid={`${componentTestId}Action`}>
            {action}
          </Styled.Action>
        )}
        {/* Slot for rendering form inputs hint */}
        {hint && (
          <Styled.Hint data-testid={`${componentTestId}Hint`}>{hint}</Styled.Hint>
        )}
      </Styled.Header>
      {/* Slot for rendering form input content */}
      <Styled.Content
        data-testid={`${componentTestId}Content`}
        hasBorder={hasBorder}
      >
        {/* Slot for rendering icon nodes */}
        {leftNode && (
          <Styled.LeftNode data-testid={`${componentTestId}LeftNode`}>
            {leftNode}
          </Styled.LeftNode>
        )}
        {children}
        {/* Slot for rendering icon nodes */}
        {rightNode && (
          <Styled.RightNode data-testid={`${componentTestId}RightNode`}>
            {rightNode}
          </Styled.RightNode>
        )}
      </Styled.Content>
      {/* Slot for rendering field message */}
      {message && (
        <Styled.Message data-testid={`${componentTestId}Message`}>
          {message}
        </Styled.Message>
      )}
      {/* Slot for rendering form inputs footer */}
      {(hasFixedFooter || errorMessage) && (
        <Styled.Footer>
          {errorMessage && (
            <Styled.ErrorContainer>
              <Icon icon="error" size="small" />
              <Styled.ErrorMessage
                data-testid={`${componentTestId}ErrorMessage`}
              >
                {errorMessage}
              </Styled.ErrorMessage>
            </Styled.ErrorContainer>
          )}
        </Styled.Footer>
      )}
    </Styled.Wrapper>
  );
};
