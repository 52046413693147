import type { BadgeProps } from './index.types';
import React, { useMemo } from 'react';
import { theme } from '~/styles/theme/Theme';
import Styled from './index.styles';

const levels = {
  info: theme.colors.primary,
  success: theme.colors.success,
  warning: theme.colors.warning,
  danger: theme.colors.danger,
};

export type Level = keyof typeof levels;

/**
 * Badge component that displays a dot with a specified color based on the level prop.
 *
 * @component
 * @param {BadgeProps} props - The properties for the Badge component.
 * @param {'info' | 'warning' | 'error' | 'success'} [props.level='info'] - The level of the badge which determines its color.
 * @param {React.ReactNode} props.children - The content to be displayed inside the badge.
 * @returns {JSX.Element} The rendered Badge component.
 */
const Badge: React.FC<BadgeProps> = ({
  level = 'info',
  size = 'small',
  children,
  ...props
}: BadgeProps): JSX.Element => {
  const color = levels[level] || theme.colors.primary;

  const badgeContent = useMemo(() => {
    if (!children || size === 'small') return;

    if (!isNaN(Number(children))) {
      return Number(children) > 99 ? '99+' : children;
    }

    return children;
  }, [children, size]);

  return (
    <Styled.Dot $color={color} $size={size} {...props}>
      {badgeContent}
    </Styled.Dot>
  );
};

export default Badge;
