import { MSG_API_BASE_URI } from 'Constants/env';
import { MobXProviderContext } from 'mobx-react';
import { useCallback, useContext, useRef } from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { resizeImage } from 'Utils/resizeImage';
import { hexEncode } from 'Utils/stringUtils';
import API, { PURE_API } from '~/api';
import { useAttachmentStore } from '../../store/attachments';
import { AttachmentItem, S3Object } from '../../store/attachments/index.types';

interface UseFileUploadProps {
  uploadAll: (attachments: AttachmentItem[]) => Promise<S3Object[]>;
  cancelUpload: (attachmentId: AttachmentItem['id']) => void;
}

const createS3Object = async (
  fileHandle: {
    filename: string;
    contentType: string;
    generatePreviewLink: boolean;
    previewContentType?: string;
  },
  signal: AbortSignal
) => {
  return API.post<S3Object>(MSG_API_BASE_URI + 'fileHandle', fileHandle, {
    signal,
  });
};

const putContentToS3 = async (
  file: File,
  s3Object: S3Object,
  signal: AbortSignal,
  previewUrl?: boolean
) => {
  const fileNameToHex = hexEncode(file.name).toUpperCase();
  return PURE_API.put(previewUrl ? s3Object.previewUrl : s3Object.url, file, {
    headers: {
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${fileNameToHex}"`,
    },
    signal,
  });
};

async function uploadImageFile(
  file: File,
  s3Object: S3Object,
  signal: AbortSignal
): Promise<void> {
  const extension = file.type.split('/')[1];
  const shouldResize = !file.type.includes('svg');
  const fileToUpload = shouldResize ? await resizeImage(file, extension) : file;
  await putContentToS3(fileToUpload, s3Object, signal, true);
}

const useFileUpload = (): UseFileUploadProps => {
  const abortControllers = useRef(
    new Map<AttachmentItem['id'], AbortController>()
  );

  const { updateStatus, setS3Object } = useAttachmentStore();
  const { notification } = useContext<RootStoreProps>(MobXProviderContext);

  const uploadToAws = async (
    attachment: AttachmentItem,
    signal: AbortSignal
  ) => {
    const fileHandle = {
      filename: attachment?.file.name,
      contentType: attachment?.file.type,
      generatePreviewLink: true,
      previewContentType: attachment?.file.type,
    };
    const { data: s3Object } = await createS3Object(fileHandle, signal);
    if (!s3Object) {
      throw new Error('Error creating s3Object file handle');
    }

    const response = await putContentToS3(attachment.file, s3Object, signal);

    if (response.status < 400 && attachment.isImage) {
      await uploadImageFile(attachment.file, s3Object, signal);
    }

    return s3Object;
  };

  const uploadFile = async (attachment: AttachmentItem) => {
    const controller = new AbortController();
    abortControllers.current.set(attachment.id, controller);

    try {
      updateStatus(attachment.id, 'uploading');

      const s3Object = await uploadToAws(attachment, controller.signal);

      updateStatus(attachment.id, 'success');
      setS3Object(attachment.id, s3Object);

      return s3Object;
    } catch (error) {
      if (controller.signal.aborted) {
        updateStatus(attachment.id, 'canceled');
      } else {
        updateStatus(attachment.id, 'error', error.message);
        notification.addAxiosErrorNotification(error, 'Error uploading file');
      }
    } finally {
      abortControllers.current.delete(attachment.id);
    }
  };

  const uploadAll = async (
    attachments: AttachmentItem[]
  ): Promise<S3Object[]> => {
    const pendingAttachments = attachments.filter(
      (attachment) => attachment.status === 'pending'
    );
    if (pendingAttachments.length === 0) {
      return; // Early exit if no pending attachments
    }

    return Promise.all(
      pendingAttachments.map((attachment) => uploadFile(attachment))
    );
  };
  const cancelUpload = useCallback((attachmentId: AttachmentItem['id']) => {
    const controller = abortControllers.current.get(attachmentId);
    if (controller) {
      controller.abort();
    }
  }, []);

  return {
    uploadAll,
    cancelUpload,
  };
};

export default useFileUpload;
