import { convertNumberIntoE164 } from 'Utils/phoneUtil';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ConversationStore, ConversationStoreState } from './index.types';

const initialState: ConversationStoreState = {
  optOutPhoneNumbersInfo: new Map<string, boolean>(),
  mentionUsers: new Map(),
};

const conversationStore = create(
  devtools<ConversationStore>((set, get) => ({
    ...initialState,
    updateOptOutPhoneNumberInfo: (phoneNumber, isOptedOut) => {
      const e164Phone = convertNumberIntoE164(phoneNumber);

      const optOutPhoneNumbersInfo = new Map(get().optOutPhoneNumbersInfo);

      optOutPhoneNumbersInfo.set(e164Phone, isOptedOut);

      set({ optOutPhoneNumbersInfo });
    },
    getOptOutPhoneNumberInfo: (phoneNumber) => {
      if (!phoneNumber) {
        return undefined;
      }

      const e164Phone = convertNumberIntoE164(phoneNumber);

      return get().optOutPhoneNumbersInfo.get(e164Phone);
    },
    addMentionUser: (mentionUser) => {
      const mentionUsersAux = new Map(get().mentionUsers);

      mentionUsersAux.set(mentionUser.id, mentionUser);

      set({ mentionUsers: mentionUsersAux });
    },
  }))
);

export const useConversationStore = conversationStore;
