import {
  useFloating,
  useListNavigation,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
  useDismiss,
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useRole,
  MiddlewareState,
} from '@floating-ui/react';
import React, { useEffect } from 'react';
import { Trigger } from './elements/Trigger';
import { Styled } from './index.styles';
import { OptionWithImageProps, SelectProps } from './index.types';

/**
 * Select component that renders a dropdown menu with options.
 * @param {SelectProps} props - The properties for the Select component.
 * @param {number | null} props.value - The currently selected value as an index of options.
 * @param {Array<string> | React.ReactNode[]} props.options - The list of options to display in the dropdown.
 * @param {function} props.onSelectionChange - Callback function to handle selection change.
 * @returns {JSX.Element} The rendered Select component.
 * @example
 * <Select
 *   value={selectedValue}
 *   options={['Option 1', 'Option 2', 'Option 3']}
 *   onSelectionChange={(index) => setSelectedValue(index)}
 * />
 */
export function Select({ value, options, onSelectionChange, ...props }: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<typeof value>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<typeof value>(null);

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(-1),
      flip({ padding: 10 }),
      size({
        apply({
          rects,
          elements,
          availableHeight,
        }: MiddlewareState & { availableHeight: number }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = React.useRef<(HTMLElement | null)[]>([]);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, click]
  );

  useEffect(() => {
    if (value !== selectedIndex) {
      setSelectedIndex(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    setIsOpen(false);
    onSelectionChange && onSelectionChange(index);
  };

  const selectedItemLabel =
    selectedIndex !== null ? (options[selectedIndex] as string) : undefined;

  return (
    <>
      <Trigger
        aria-label={props?.label}
        ref={refs.setReference}
        tabIndex={0}
        aria-autocomplete="none"
        rightNode={<Styled.CaretIcon />}
        value={selectedItemLabel || 'Select...'}
        {...props}
        {...getReferenceProps()}
      />
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <Styled.OptionsContainer
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
              }}
              {...getFloatingProps()}
            >
              {options.map((value, i) => (
                <Styled.Option
                  key={`option-${i}`}
                  ref={(node) => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === selectedIndex && i === activeIndex}
                  $active={i === activeIndex}
                  $selected={i === selectedIndex}
                  {...getItemProps({
                    // Handle pointer select.
                    onClick() {
                      handleSelect(i);
                    },
                    // Handle keyboard select.
                    onKeyDown(event) {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSelect(i);
                      }

                      if (event.key === ' ') {
                        event.preventDefault();
                        handleSelect(i);
                      }
                    },
                  })}
                >
                  {value}
                </Styled.Option>
              ))}
            </Styled.OptionsContainer>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}


/**
 * Component that renders an option with an image and a label.
 *
 * @param {OptionWithImageProps} props - The properties for the component.
 * @param {React.ReactNode | string} props.image - The image to display. Can be a React element or a URL string.
 * @param {string} props.label - The label to display next to the image.
 *
 * @returns {JSX.Element} The rendered component.
 */
const OptionWithImage = ({ image, label }: OptionWithImageProps) => {
  const isComponent = React.isValidElement(image);
  return (
    <Styled.OptionWithImage>
      {!isComponent && typeof image === 'string' ? <img src={typeof image === 'string' ? image : ''} alt={label} /> : image}
      {label}
    </Styled.OptionWithImage>
  );
};
OptionWithImage.displayName = 'OptionWithImage';

Select.OptionWithImage = OptionWithImage;
