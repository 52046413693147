import { FloatingOverlay } from '@floating-ui/react';
import styled from 'styled-components';
import { DimmerType } from './index.types';

const Overlay = styled(FloatingOverlay)<{
  $dimmer: DimmerType;
  $legacy?: boolean;
}>`
  display: grid;
  place-items: center;

  ${({ $dimmer, theme }) => {
    if ($dimmer) {
      const backgroundColor =
        typeof $dimmer === 'object' && $dimmer.inverted
          ? `color-mix(in srgb, color-mix(in srgb, ${theme.colors.borderDark} 50%, white 50%) 80%, transparent)`
          : `color-mix(in srgb, ${theme.colors.borderDark} 80%, transparent)`;

      return `background: ${backgroundColor};`;
    }

    return '';
  }}

  ${({ $dimmer }) =>
    typeof $dimmer === 'object' &&
    $dimmer.blurring &&
    'backdrop-filter: blur(5px);'}

  ${({ $legacy }) => {
    if ($legacy) {
      return `
        background: #0009;
        backdrop-filter: blur(5px) grayscale(.7);
      `;
    }

    return '';
  }}
`;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.base};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  position: relative;
`;

export const Styled = {
  Overlay,
  Container,
};
