import styled from 'styled-components';
import { EmojiButton } from 'Components/shared/EmojiButton';
import { Icon } from 'Components/shared/Icon';

const StyledEmojiButton = styled(EmojiButton)`
  width: 20px;
  height: 40px;
  right: 0;
  margin: 0;
  align-content: center;

  & > * { // overwrites the base EmojiButton styles for the EmojiWrapper element
    width: 20px;
    height: 40px;
  }
`;

const PickerButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font-size: 15px;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const EmojiIcon = styled(Icon).attrs(() => ({
  icon: 'add-reaction',
  size: 'small',
}))`
  &:hover {
    color: ${({ theme }) => theme.colors.darkGray} !important;
  }
`;

export const Styled = {
  EmojiButton: StyledEmojiButton,
  PickerButton,
  EmojiIcon,
};
