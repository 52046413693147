import { Icon } from 'Components/shared/Icon';
import styled from 'styled-components';
import { InputField } from 'Components/shared/forms/InputField';

const Trigger = styled(InputField)<{ $isOpen?: boolean }>`
  cursor: pointer;
`;

const CaretIcon = styled(Icon).attrs(() => ({
  icon: 'caret-down',
  size: 'mini',
}))<{ $flip?: boolean }>`
  display: flex;
  transform: ${({ $flip }) => ($flip ? 'rotate(180deg)' : 'none')};
  transition: transform 0.3s;
`;

const OptionsContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0px 0px 4px 4px;
  overflow: auto;
  outline: 0;
  user-select: none;
`;

const Option = styled.div<{ $active?: boolean, $selected: boolean }>`
  padding: 7px 15px;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.text};
  outline: 0;
  background-color: ${({ $active, theme }) =>
    $active ? theme.colors.backgroundHover : 'transparent'};
  font-weight: normal;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundHover};
  }
`;

const OptionWithImage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

`

export const Styled = {
  Trigger,
  CaretIcon,
  OptionsContainer,
  Option,
  OptionWithImage,
};
