/**
 * Dialog component that wraps content inside a modal.
 *
 * @param {React.ReactNode} children - The content to be displayed inside the dialog.
 * @param {string} title - The title of the dialog.
 * @param {string} body - The body content of the dialog, used if children are not provided.
 * @param {React.ReactNode} mainActions - The main actions to be displayed in the footer's right slot.
 * @param {React.ReactNode} secondaryActions - The secondary actions to be displayed in the footer's left slot.
 * @param {boolean} showCloseButton - Flag to show or hide the close button in the modal header.
 * @param {DialogProps} props - Additional props to be passed to the Modal component.
 *
 * @returns {JSX.Element} The rendered Dialog component.
 */
import { Modal } from 'Components/shared/Modal';
import React from 'react';
import { Styled } from './index.styles';
import { DialogProps } from './index.types';

export const Dialog = ({
  children,
  title,
  body,
  mainActions,
  secondaryActions,
  showCloseButton,
  ...props
}: DialogProps) => {
  return (
    <Modal {...props}>
      <Styled.Container>
        <Modal.Header showCloseButton={showCloseButton}>{title}</Modal.Header>
        {children || <Modal.Body>{body}</Modal.Body>}
        <Modal.Footer
          leftSlot={secondaryActions}
          rightSlot={mainActions}
        ></Modal.Footer>
      </Styled.Container>
    </Modal>
  );
};
