import {
  TopBarDropdownContext,
  type TopBarDropdownVariants,
} from 'Components/TopBar/contexts/TopBarDropdownContext';
import { DialpadIconButton } from 'Components/TopBar/DialpadIconButton';
import {
  createConversationsOptions,
  createExtrContactOptions,
  sortAndDeduplicateOptions,
  filterSelectedUsers,
} from 'Components/TopBar/helpers';
import {
  checkIfIsNumericSearch,
  useTopBarDropdownFilter,
} from 'Components/TopBar/hooks/useTopBarDropdownFilter';
import { debounce } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { TopBarDropdownActionButtons } from './TopBarDropdownActionButtons';
import { Styled } from './TopBarDropdownContent.styles';

let timeout: number;
let hasMoreData = true;

export const TopBarDropdownContent: React.FC = observer(() => {
  const {
    ui: {
      handleLoadMoreContacts,
      selectedTopBarUsers,
      setSelectedTopBarUsers,
      topBarContactPageNumber,
      setTopBarSearchValue,
      setTopBarContactPageNumber,
    },
    conversation: {
      FavoriteConversations,
      setChannelInfoDetails,
      handleAdHocGroups,
      changeConvTopic,
      loadOrCreateConversationWithPost,
    },
    conversation,
    person,
    search,
  } = React.useContext<RootStoreProps>(MobXProviderContext);
  const {
    selection,
    setSelection,
    options,
    setOptions,
    favoriteList,
    mapFavoritesOptions,
    makeCall,
  } = React.useContext(TopBarDropdownContext);

  const navigate = useNavigate();

  const [showLoader, setShowLoader] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [activeConversationId, setActiveConversationId] = React.useState('');
  const [optionCountByType, setOptionCountByType] = React.useState<{
    external: number;
    favorites: number;
  }>({ external: 0, favorites: 0 });

  const loadDropDownOptions = async (
    appendResult = false,
    removeSelection = false
  ) => {
    let allPersons = [];
    let allConv = [];

    const alreadyLoaded =
      options.length ||
      person.allContactsTopBar.length ||
      FavoriteConversations.length;

    const isNumericSearch = checkIfIsNumericSearch(
      searchInput,
      person.phoneNumInvalid
    );

    if (alreadyLoaded && appendResult) {
      allPersons = search.directoryMap?.['USERS']?.data?.results || [];

      if (!isNumericSearch) {
        allConv = search.directoryMap?.['CONVERSATIONS'].data?.results || [];
      }
    } else {
      allPersons =
        (await search.getDirectorySearch('USERS'))?.data?.people || [];

      if (!isNumericSearch) {
        allConv =
          (
            await search.getPersonContactSearch(
              'CONVERSATIONS',
              '',
              topBarContactPageNumber,
              false
            )
          ).data?.results || [];
      }
    }

    const initialOptions = [
      ...mapFavoritesOptions([...FavoriteConversations]),
      ...createConversationsOptions(
        [...allConv, ...allPersons],
        changeConvTopic,
        isNumericSearch,
        searchInput
      ),
      ...createExtrContactOptions(
        person.allContactsTopBar,
        isNumericSearch,
        searchInput
      ),
    ];

    setOptions([
      // clears phone numbers from options if there is no selection
      ...(removeSelection ? [] : filterSelectedUsers(selection.users, options)),
      ...initialOptions,
    ]);
  };

  const { filterDebounce, filter } = useTopBarDropdownFilter({
    setShowLoader,
    loadDropDownOptions,
  });

  React.useEffect(() => void loadDropDownOptions(), []);

  React.useEffect(() => {
    if (searchInput) return;
    if (
      FavoriteConversations.length &&
      FavoriteConversations.length !== optionCountByType.favorites
    ) {
      setOptionCountByType((prevProps) => ({
        ...prevProps,
        favorites: FavoriteConversations.length,
      }));
      loadDropDownOptions(!!optionCountByType.favorites || false);
    }
    if (
      person.allContactsTopBar.length &&
      person.allContactsTopBar.length !== optionCountByType.external
    ) {
      setOptionCountByType((prevProps) => ({
        ...prevProps,
        external: person.allContactsTopBar.length,
      }));
      loadDropDownOptions(!!optionCountByType.external || false);
    }
  }, [
    searchInput,
    FavoriteConversations.length,
    person.allContactsTopBar.length,
    optionCountByType.external,
    optionCountByType.favorites,
  ]);

  React.useEffect(() => {
    //after making new group, clear selected users
    if (!selectedTopBarUsers && selection.users?.length)
      setSelection({ users: [], variant: null });
  }, [selectedTopBarUsers, selection.users]);

  React.useEffect(() => {
    const element: any = document.querySelector('#top-bar-dropdown .menu');
    if (element) {
      element.onscroll = async (e: any) => {
        const bottom =
          e.target.scrollHeight -
          e.target.scrollTop -
          e.target.clientHeight -
          10;
        bottom <= 100 && hasMoreData && (await handleLoadContacts());
      };
    }
  });

  const handleOnChangeDropdown = (e, d) => {
    e.stopPropagation();

    const handleSelection = (
      variant: Extract<
        TopBarDropdownVariants,
        'internal-contact' | 'adhoc-phone-number' | 'external-contact'
      >,
      callOnEnter = false
    ) => {
      setSelection({ users: d.value, variant });

      if (e.currentTarget.id !== 'top-bar-dropdown') {
        setActiveConversationId('');
      }

      setSelectedTopBarUsers(d.value);

      if (d.value.length === 1) {
        if (e.key === 'Enter' && callOnEnter) {
          void makeCall({ phoneNumbers: [selectedOption?.phoneNumber] });
        } else {
          variant === 'internal-contact'
            ? void openMessageChat(d.value[0], null)
            : void openMessageChat(null, d.value[0]);
        }
      } else {
        const channelType = ['adhoc-phone-number', 'external-contact'].includes(
          variant
        )
          ? 'phone'
          : 'person';

        // When there is more than 1 selection, create a temporary ad hoc group
        setChannelInfoDetails(
          conversation.channelInfoDetails.channelConversationId,
          'new',
          channelType
        );

        void handleAdHocGroups(d.value)?.then(({ id }) =>
          setActiveConversationId(id.toString())
        );
      }
    };

    const selectedOption = options.find(
      ({ value }) => d.value[d.value.length - 1] === value
    );

    // Disable group if there is an internal-contact selection
    if (selectedOption?.variant === 'group' && selection.variant) return;

    // When user removed all selections
    if (selection.variant && !d.value.length) {
      refreshData(true);
      return;
    }

    // When user clicked on a group option
    if (selectedOption?.variant === 'group') {
      navigate(`/chat/conversations/${d.value[0]}/menu`);
      refreshData();
      return;
    }

    // When user clicked on a external-contact option
    if (selectedOption?.variant === 'external-contact') {
      handleSelection('external-contact', true);
    }

    // When user clicked on an internal-contact option
    if (selectedOption?.variant === 'internal-contact') {
      handleSelection('internal-contact', false);
    }

    // When user clicked on an adhoc-phone-number option
    if (selectedOption?.variant === 'adhoc-phone-number') {
      handleSelection('adhoc-phone-number', true);
    }

    setSearchInput('');
    setTopBarSearchValue('');
    const inputElement = document.querySelector('#top-bar-dropdown input');
    if (inputElement) {
      (inputElement as any).focus();
      (inputElement as any).onkeydown = (e) => {
        if (e.key === 'Tab') {
          e.preventDefault();
          e.stopPropagation();
          document.getElementById('message-input').click();
        }
      };
    }
  };

  const handleFocus = async () => {
    if (!searchInput && options.length < 20) await loadDropDownOptions();
    document
      .querySelector<HTMLInputElement>('#top-bar-dropdown .dropdown input')
      .focus();
  };

  const handleLoadContacts = debounce(async () => {
    if (showLoader) return;

    //if selected any dont load extrContacts
    setShowLoader(true);
    const isEmpty = await handleLoadMoreContacts(
      selection.users.length <= 0,
      false
    );
    await loadDropDownOptions(true);
    isEmpty && (hasMoreData = false);
    setShowLoader(false);
  }, 500);

  const handleScrollDown = () => {
    timeout = window.setTimeout(() => {
      const input = document.querySelector<HTMLInputElement>(
        '#top-bar-dropdown input'
      );
      if (input) {
        void handleFocus();
        input.onkeyup = (e: KeyboardEvent) =>
          e.keyCode === 13 && setSearchInput('');
      }

      if (!hasMoreData) clearTimeout(timeout);
    }, 500);
  };

  const refreshData = (removeSelection = false) => {
    //when clicked on contact
    if (!removeSelection && selection.users.length) return;
    setTopBarContactPageNumber(1);
    setTopBarSearchValue('');
    setSearchInput('');
    filter('');
    setSelection({ users: [], variant: null });
    hasMoreData = true;
    void loadDropDownOptions(false, removeSelection);
  };

  const openMessageChat = (personId, phone) =>
    loadOrCreateConversationWithPost(personId, personId ? null : phone).then(
      ({ data }) => navigate(`/chat/conversations/${data.id}/menu`)
    );

  const createFinalOptions = () => {
    let filteredOptions = options;

    if (selection.variant === 'internal-contact') {
      filteredOptions = options.filter(({ variant }) => variant !== 'group');
    } else if (selection.variant === 'adhoc-phone-number') {
      // When current selection variant is adhoc-phone-number, we want to still be able to search for external-contact too
      filteredOptions = options.filter(
        ({ variant }) =>
          variant === 'adhoc-phone-number' || variant === 'external-contact'
      );
    }

    const normalizedOptions = filteredOptions.map((option) => ({
      ...option,
      disabled:
        selection.variant &&
        // We don't allow selection of an item with a different variant than the current selection variant
        selection.variant !== option.variant &&
        // Exception to previous rule -> when current selection variant is external-contact, we allow selection of an adhoc-phone-number item, and vice-versa
        !(
          selection.variant === 'external-contact' &&
          option.variant === 'adhoc-phone-number'
        ) &&
        !(
          selection.variant === 'adhoc-phone-number' &&
          option.variant === 'external-contact'
        ),
    }));

    return sortAndDeduplicateOptions(
      normalizedOptions,
      searchInput ? favoriteList : {}
    );
  };

  const loggedInUserIsSelected = !!selection.users?.includes(
    person.loggedInPersonId.toString()
  );

  return (
    <Styled.TopBarDropdown id="top-bar-dropdown">
      <Styled.TopBarDropdownContainer className="dropdown-wrapper">
        <Styled.Dropdown
          placeholder="Search contact"
          fluid
          multiple
          search
          icon={false}
          searchQuery={searchInput}
          selection
          options={createFinalOptions()}
          onChange={handleOnChangeDropdown}
          onClick={handleFocus}
          onSearchChange={(e, { searchQuery }) => {
            hasMoreData = true;
            setSearchInput(searchQuery);
            filterDebounce(searchQuery);
          }}
          onOpen={() => {
            setTopBarContactPageNumber(1);
            setTopBarSearchValue('');
            setSearchInput('');
            hasMoreData = true;
            handleScrollDown();
          }}
          value={selection.users}
          onClose={refreshData}
          {...(selection.variant === 'adhoc-phone-number' && {
            noResultsMessage:
              'To create a group you can only add phone numbers',
          })}
        />
        {/* Only display video call option if there are any participants other than the logged in user */}
        {!!selection.users?.length && !loggedInUserIsSelected && (
          <TopBarDropdownActionButtons
            {...{ activeConversationId, setActiveConversationId }}
          />
        )}
        <Styled.SearchIcon icon={showLoader ? 'spinner' : 'search'} />
      </Styled.TopBarDropdownContainer>
      <DialpadIconButton />
    </Styled.TopBarDropdown>
  );
});
