import type { RectangularButtonProps } from './index.types';
import React from 'react';
import { Styled } from './index.styles';

export const RectangularButton = ({
  testid,
  variant = 'primary',
  active = false,
  onClick,
  disabled = false,
  ...props
}: RectangularButtonProps) => (
  <Styled.Button
    $variant={variant}
    $active={active}
    data-testid={`${testid}-rectangularButton`}
    {...(!disabled && { onClick })}
    {...{ disabled, ...props }}
  />
);
