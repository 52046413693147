import styled, { css } from 'styled-components';
import { BadgeProps } from './index.types';


const computeDimensions = (size: BadgeProps['size']) => {
  switch (size) {
    case 'medium':
      return css`
        min-width: 18px;
        height: 18px;
        border-radius: 36px;
      `;
    case 'small':
    default:
      return css`
        min-width: 10px;
        height: 10px;
        border-radius: 20px;
      `;
  }
}

const Dot = styled.div<{ $color: string, $size: BadgeProps['size'] }>`
  ${({ $size }) => computeDimensions($size)};
  width: 100%;
  padding-inline: 4px;
  background-color: ${({ $color }) => $color};
  border: 1px solid white;
  font: normal normal 600 13px/16px Open Sans;
  text-align: center;
  color: white;
`;

const Styled = {
  Dot,
};

export default Styled;
