import type { PresenceDialogProps, FormData } from './index.types';
import { Dialog } from 'Components/shared/Dialog';
import { InputField } from 'Components/shared/forms/InputField';
import { Select } from 'Components/shared/forms/Select';
import { RectangularButton } from 'Components/shared/RectangularButton';
import { possibleStatuses, STATE_PRESENCE } from 'Constants/enums';
import { useForm } from 'Hooks/form';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { useContext, useMemo } from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { EmojiPickerButton } from './elements/EmojiPickerButton';
import { Styled } from './index.styles';

export const PresenceDialog = observer(
  ({ onClose, body, ...props }: PresenceDialogProps) => {
    const { ui: uiStore, person } =
      useContext<RootStoreProps>(MobXProviderContext);
      const { title, message } = uiStore.selectPersonMessageStatus(person.loggedInPersonId);
      const userPresence = uiStore.selectPersonPresenceStatus(person.loggedInPersonId).state;

    const {
      formData,
      resetForm,
      updateFormField,
      updateFormData,
    } = useForm<FormData>({
      userStatusMessage: message,
      userStatusEmoji: title,
      userPresence: userPresence,
    });

    const selectedPresenceIndex = useMemo(
      () =>
        possibleStatuses.findIndex(
          (status) => status.value === formData.userPresence
        ),
      [formData.userPresence]
    );

    const handleOnClose = () => {
      resetForm();
      onClose();
    };

    const handleOnSubmit = () => {
      void uiStore.updateMessageStatus(
        formData.userStatusEmoji,
        formData.userStatusMessage
      );
      void uiStore.updatePresence(formData.userPresence || 'Active', true, '');
      onClose();
    };

    const presenceStatusMessage = useMemo(() => {
      if (formData.userPresence === 'DoNotDisturb') {
        return 'DND hides received calls in the web and mobile apps. Resets in 24h.';
      }
      return 'A manually selected status resets in 2h.';
    }, [formData.userPresence]);

    return (
      <Dialog
        title="Status and availability"
        mainActions={
          <>
            <RectangularButton variant="secondary" onClick={handleOnClose}>
              Cancel
            </RectangularButton>
            <RectangularButton onClick={handleOnSubmit}>
              Save
            </RectangularButton>
          </>
        }
        onClose={handleOnClose}
        {...props}
      >
        <Styled.Content>
          <InputField
            id="userStatusMessage"
            value={formData.userStatusMessage}
            onChange={(event) =>
              updateFormField('userStatusMessage', event.target.value)
            }
            label="Personalized status"
            placeholder="Type a message or pick an emoji"
            labelWeight="bold"
            leftNode={
              <EmojiPickerButton
                selectedEmoji={formData.userStatusEmoji}
                onChange={(emoji) =>
                  updateFormField('userStatusEmoji', emoji)
                }
              />
            }
            rightNode={
              (formData.userStatusMessage?.length > 0 ||
                formData.userStatusEmoji?.length > 0) && (
                <Styled.ClearFieldButton
                  onClick={() =>
                    updateFormData({
                      userStatusMessage: '',
                      userStatusEmoji: '',
                    })
                  }
                >
                  <Styled.ClearFieldIcon />
                </Styled.ClearFieldButton>
              )
            }
          />

          <Select
            id="userPresenceState"
            value={selectedPresenceIndex}
            onSelectionChange={(selectedIndex: number) =>
              updateFormField('userPresence', possibleStatuses.at(selectedIndex).value)
            }
            label="Choose how to appear to others"
            placeholder="Type a message or pick an emoji"
            labelWeight="bold"
            hint="Note that this status is unrelated to the personal ring strategy selected."
            message={presenceStatusMessage}
            options={possibleStatuses.map((status, i) => (
              <Select.OptionWithImage
                key={`option-${i}`}
                image={
                  <Styled.PresenceDot
                    conversationId={null}
                    showUnread={false}
                    selectUnreadCounts={() => null}
                    state={status.value as STATE_PRESENCE}
                  />
                }
                label={status.text}
              />
            ))}
          />
        </Styled.Content>
      </Dialog>
    );
  }
);
