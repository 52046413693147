import React from 'react';
import { Styled } from './index.styles';
import { FooterProps } from './index.types';

export const Footer = ({
  leftSlot,
  rightSlot,
  children,
  ...props
}: FooterProps) => {
  return (
    (children || leftSlot || rightSlot) && (
      <Styled.Footer {...props}>
        {leftSlot && <Styled.LeftSlot>{leftSlot}</Styled.LeftSlot>}
        {children}
        {rightSlot && <Styled.RightSlot>{rightSlot}</Styled.RightSlot>}
      </Styled.Footer>
    )
  );
};
