import { ModalContext } from 'Components/shared/Modal';
import React, { useContext } from 'react';
import { Styled } from './index.styles';
import { HeaderProps } from './index.types';

export const Header = ({
  children,
  showCloseButton = true,
  ...props
}: HeaderProps) => {
  const modalContext = useContext(ModalContext);

  return (
    (children || showCloseButton) && (
      <Styled.Header {...props}>
        <Styled.Title variant="h4">{children}</Styled.Title>
        {showCloseButton && (
          <Styled.CloseButton
            disabled={!modalContext.dismissable}
            onClick={() => {
              modalContext.dismissable && modalContext.close();
            }}
          >
            <Styled.CloseIcon />
          </Styled.CloseButton>
        )}
      </Styled.Header>
    )
  );
};
