import type { BannerProps, BannerType } from './index.types';
import { Icon, IconDefinition } from 'Components/shared/Icon';
import React from 'react';
import { Styled } from './index.styles';

const icons: Record<BannerType, IconDefinition> = {
  success: 'success-filled',
  error: 'error-filled',
  warning: 'warning-filled',
  info: 'info-filled',
};

export const Banner = ({
  type,
  id,
  title = null,
  message = null,
  action,
  onClose,
  isFullWidth = false,
  showCloseButton = true,
  variant = 'default',
}: BannerProps): React.ReactElement => {
  const icon = icons[type];
  const computedActions = Array.isArray(action) ? action : [action];

  return (
    <Styled.Container $type={type} id={id} $isFullWidth={isFullWidth}>
      <Styled.Wrapper>
        <Styled.Content $variant={variant}>
          {icon && (
            <Styled.IconWrapper $type={type} $variant={variant}>
              <Icon icon={icon} />
            </Styled.IconWrapper>
          )}
          <Styled.TextContainer>
            {title && variant === 'default' && (
              <Styled.Title>{title}</Styled.Title>
            )}
            {message && (
              <Styled.MessageContainer
                $isOnlyMessage={!title}
                $variant={variant}
              >
                <Styled.Message>{message}</Styled.Message>
                {variant === 'inline' && action && (
                  <Styled.ActionContainer $variant={variant}>
                    {computedActions.map((a, index) => (
                      <Styled.Action key={index} onClick={a.onClick}>
                        {a.label}
                      </Styled.Action>
                    ))}
                  </Styled.ActionContainer>
                )}
              </Styled.MessageContainer>
            )}
          </Styled.TextContainer>
        </Styled.Content>
        {showCloseButton && variant === 'default' && (
          <Styled.CloseButton onClick={onClose}>
            <Icon icon="cancel" />
          </Styled.CloseButton>
        )}
      </Styled.Wrapper>
      {variant === 'default' && action && (
        <Styled.FooterActions>
          {computedActions.map((a, index) => (
            <Styled.Action key={index} onClick={a.onClick}>
              {a.label}
            </Styled.Action>
          ))}
        </Styled.FooterActions>
      )}
    </Styled.Container>
  );
};
