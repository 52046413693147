import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  min-width: 500px;
`;

export const Styled = {
  Container,
};
