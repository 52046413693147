import { useAuth0 } from '@auth0/auth0-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { AppError } from '../../components/AppError';
import { AppLoader } from '../../components/AppLoader';
import {
  DOWNLOAD_APP_URL_MAC,
  DOWNLOAD_APP_URL_WINDOWS,
  IS_ELECTRON,
} from '../../constants/env';
import {
  handleAuth0CodeReceived,
  sendIpcAuth0LoginUrl,
  focusAuth0Window,
  handleAuth0WindowClose,
} from '../../utils/ipcRendererEvents';
import { AppWithErrorHandler } from '../App';
import { IMainApp } from './interfaces';

export const MainApp = ({ rootStore, browserHistory }: IMainApp) => {
  const auth0 = useAuth0();
  const ldClient = useLDClient();
  const { personStore } = rootStore;
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadingElectron, setIsLoadingElectron] = useState<boolean>(false);
  const isAuth0WindowOpen = useRef<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  useEffect(() => {
    handleAuth0WindowClose(() => {
      isAuth0WindowOpen.current = false;
    });
  }, []);

  useEffect(() => {
    !search && setSearch(browserHistory.location.search);
    personStore.setAuth0(auth0);
    personStore.setLDClient(ldClient);
  }, [ldClient, personStore]);

  useEffect(() => {
    if (auth0.isLoading) {
      return;
    }

    void (async () => {
      if (
        !auth0.isAuthenticated &&
        !(
          search.includes('state=calendars') ||
          search.includes('state=contacts')
        )
      ) {
        IS_ELECTRON ? await loginElectron() : await auth0.loginWithRedirect();
        return;
      }

      await loginWithToken();
    })();
  }, [auth0.isLoading]);

  const loginElectron = async () => {
    try {
      if (isAuth0WindowOpen.current) {
        focusAuth0Window();
        return;
      }
      isAuth0WindowOpen.current = true;

      const auth0LoginUrl = await auth0.buildAuthorizeUrl({
        prompt: 'login',
        max_age: 0,
        sso: false,
        rememberLastLogin: false,
      });

      handleAuth0CodeReceived(async (code, url) => {
        try {
          setIsLoadingElectron(false);
          await auth0.handleRedirectCallback(decodeURIComponent(url));
          await loginWithToken();
        } catch (error) {
          console.error(error);
        }
      });
      sendIpcAuth0LoginUrl(auth0LoginUrl);
      setIsLoadingElectron(true);
    } catch (error) {
      setIsLoadingElectron(false);
      isAuth0WindowOpen.current = false;
    }
  };

  const loginWithToken = async () => {
    try {
      const token = await personStore.exchangeForBVToken({
        redirect_uri: window.location.href,
      });
      await personStore.loginWithExistingToken(token);
    } catch (e) {
      setError(e.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const downloadApp = () => {
    const macosPlatforms = [
      'Macintosh',
      'MacIntel',
      'MacPPC',
      'Mac68K',
      'darwin',
    ];
    const url = macosPlatforms.includes(window.navigator.platform)
      ? DOWNLOAD_APP_URL_MAC
      : DOWNLOAD_APP_URL_WINDOWS;
    window.open(url, '_blank');
  };

  const appIsStillLoading = () =>
    auth0.isLoading || !auth0.user || loading || personStore.isLoggingOut;

  if (appIsStillLoading()) {
    if (isLoadingElectron) {
      const installedVersion = navigator.userAgent
        .split(' ')
        ?.find((el) => el.includes('bhive-communicator-electron/'))
        ?.split('/')[1];
      const versionLocaleCompare = installedVersion.localeCompare('1.2.49');
      return (
        <Grid textAlign="center" verticalAlign="middle" className="login">
          <Grid.Column style={{ maxWidth: 450 }} className="login-container">
            {versionLocaleCompare === -1 ? (
              <>
                <p>Please update to the latest version!</p>
                <a href="javascript:void(0)" onClick={downloadApp}>
                  Download here
                </a>
                <p className="app-notice-message">
                  * Note: Communicator may automatically shut-down after
                  installing the update. This is part of the update process.
                  Please restart Communicator after this shutdown.
                </p>
              </>
            ) : (
              <button
                className="ui button"
                onClick={loginElectron}
                data-testid="electron-login-button"
              >
                Login
              </button>
            )}
          </Grid.Column>
        </Grid>
      );
    }
    return <AppLoader />;
  }

  return error ? (
    <AppError error={error} />
  ) : (
    <AppWithErrorHandler
      //@ts-ignore
      searchString={search}
      ui={rootStore.uiStore}
      person={rootStore.personStore}
      browserHistory={browserHistory}
      message={rootStore.messageStore}
      phoneStore={rootStore.phoneStore}
    />
  );
};
