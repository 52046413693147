import {PresenceDialog} from 'Components/PresenceDialog';
import { Banner } from 'Components/shared/Banner';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';

export const DNDBannerWrapper = observer(() => {
  const [showMessageStatusModal, setShowMessageStatusModal] =
    React.useState<boolean>(false);

  const { ui } =
    React.useContext<RootStoreProps>(MobXProviderContext);

  return (
    <>
      <Banner
        isFullWidth
        type="info"
        id="dnd-banner-wrapper"
        message="Do not disturb is enabled. While on, your calls will be hidden in the apps."
        showCloseButton={false}
        variant="inline"
        action={[
          {
            label: 'Dismiss',
            onClick: () => ui.setShouldShowDndBanner(false),
          },
          {
            label: 'Change',
            onClick: () => setShowMessageStatusModal(true),
          },
        ]}
      />

      <PresenceDialog
        open={showMessageStatusModal}
        onClose={() => setShowMessageStatusModal(!showMessageStatusModal)}
      />
    </>
  );
});
