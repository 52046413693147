import React from 'react';
import { Styled } from './index.styles';
import { EmojiPickerButtonProps } from './index.types';
export const EmojiPickerButton = ({ onChange, selectedEmoji }: EmojiPickerButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Styled.EmojiButton
      portalTarget={document.body}
      isOpen={isOpen}
      onOpen={() => {}}
      onClose={() => {
        setIsOpen(false);
      }}
      onChooseEmoji={(e: { native: string }) => onChange(e.native)}
      placement="bottom-start"
    >
      <Styled.PickerButton
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {selectedEmoji || <Styled.EmojiIcon />}
      </Styled.PickerButton>
    </Styled.EmojiButton>
  );
};
