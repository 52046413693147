import type {
  InputFieldBorder,
  InputFieldStyleProps,
} from 'Components/shared/forms/InputField/index.types';
import styled, { css } from 'styled-components';

const createBorderStyles = (
  borders: InputFieldBorder[],
  isFocused: boolean,
  hasError: boolean,
  isMouseFocused: boolean
) => css`
  ${borders.map((border) => {
    switch (border) {
      case 'all':
        return 'border-width: 1px;';
      case 'top':
        return 'border-top-width: 1px;';
      case 'right':
        return 'border-right-width: 1px;';
      case 'bottom':
        return 'border-bottom-width: 1px;';
      case 'left':
        return 'border-left-width: 1px;';
      default:
        return '';
    }
  })}
  border-color: ${({ theme }) =>
    hasError
      ? theme?.colors?.error
      : isFocused
      ? theme?.colors?.primary
      : theme?.colors?.border};
  border-style: solid;

  ${({ theme }) => isFocused && !isMouseFocused ? `outline: 1px solid ${theme.colors.primary};` : 'outline: 0;'}
`;

const Trigger = styled.div<InputFieldStyleProps>`
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme?.colors?.text};
  background-color: ${({ theme }) => theme?.colors?.base};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-radius: 3px;
  padding-right: ${({ $rightNode }) => ($rightNode ? '45px' : '15px')};
  padding-left: ${({ $leftNode }) => ($leftNode ? '45px' : '15px')};
  padding-bottom: 11px;
  padding-top: 11px;
  ${({ $border = 'none', $hasError = false, $isFocused, $isMouseFocused = false }) =>
    createBorderStyles($border as InputFieldBorder[], $isFocused, $hasError, $isMouseFocused)}

  &::placeholder {
    color: ${({ $placeholderTextColor }) => $placeholderTextColor};
  }
`;

export const Styled = {
  Trigger,
};
