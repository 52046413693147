import { Link as LinkComp } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { Icon } from '../shared/Icon';

const Link = styled(LinkComp)`
  display: flex;
`;

const StyledIcon = styled(Icon)<{ $isActive: boolean }>`
  ${({ $isActive }) => $isActive && `display: none;`}
`;

const StyledIconFilled = styled(Icon)<{
  $isActive?: boolean;
}>`
  display: none;

  ${({ $isActive }) => $isActive && `display: block;`}
`;

const Column = styled(Grid.Column)<{
  $isActive: boolean;
}>`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 4px;
  position: relative;
  color: ${({ theme }) => theme.colors.text};
  &:before {
    content: '';
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: ${({ theme }) => theme.transitions.ease};
    ${({ $isActive }) =>
      $isActive &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    font-weight: bold;

    ${StyledIcon} {
      display: none;
    }
    ${StyledIconFilled} {
      display: block;
    }
  }
`;

const Label = styled.span<{ $isActive?: boolean }>`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1em;

  ${({ $isActive }) => $isActive && 'font-weight: bold;'}
`;

const BadgeContainer = styled.div`
  position: absolute;
  left: 37px;
  bottom: 36px; // or top 10px;
`;

export const Styled = {
  Column,
  Label,
  Link,
  Icon: StyledIcon,
  IconFilled: StyledIconFilled,
  BadgeContainer,
};
