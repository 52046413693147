import { isEqual } from 'lodash';
import { useEffect, useMemo, useState, useRef } from 'react';

export const useForm = <T,>(data: T) => {
  const initialFormDataRef = useRef<T>(data);

  const [formData, setFormData] = useState<T>({ ...initialFormDataRef.current });

  useEffect(() => {
    if (!isEqual(initialFormDataRef.current, data)) {
      initialFormDataRef.current = data;
      setFormData({ ...data });
    }
  }, [data]);

  const isFormDirty = useMemo(
    () => !isEqual(initialFormDataRef.current, formData),
    [formData]
  );

  const updateFormField = (field: keyof T, value: T[keyof T]) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const updateFormData = (newData: Partial<T>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...newData,
    }));
  };

  const resetForm = () => {
    setFormData({ ...initialFormDataRef.current });
  };

  return {
    formData,
    updateFormData,
    updateFormField,
    isFormDirty,
    resetForm,
  };
};
