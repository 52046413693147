import { Heading } from 'Components/shared/Heading';
import styled from 'styled-components';
import { Icon } from 'Components/shared/Icon';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled(Heading)`
  font-weight: bold;
  margin: 0;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  translate: 3px;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'cancel-heavy',
  size: 'small',
}))`
  &:hover {
    color: ${({ theme }) => theme.colors.darkGray} !important;
  }
`;

export const Styled = {
  Header,
  Title,
  CloseButton,
  CloseIcon,
};
