import styled from 'styled-components';

const Footer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const LeftSlot = styled.div`
  display: inline-flex;
  margin-right: auto;
  gap: 15px;
`;

const RightSlot = styled.div`
  display: inline-flex;
  margin-left: auto;
  gap: 15px;
`;

export const Styled = {
  Footer,
  LeftSlot,
  RightSlot,
};
