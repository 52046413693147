import { DNDBannerWrapper } from 'Components/DNDBannerWrapper';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';
import { ContentAreaProps } from './types';

export const ContentArea: React.FC<ContentAreaProps> = observer(
  ({ id, children, inlineTopBanner }) => {
    const { ui, person } =
      React.useContext<RootStoreProps>(MobXProviderContext);

    const showBanner =
      ui.shouldShowDndBanner &&
      ui.selectPersonPresenceStatus(person.loggedInPersonId).state ===
        'DoNotDisturb';

    return (
      <Styled.ContentAreaWrapper {...{ id }}>
        {showBanner ? <DNDBannerWrapper /> : inlineTopBanner}
        <Styled.ContentArea $inlineTopBanner={!!inlineTopBanner}>
          <Styled.Content>{children}</Styled.Content>
        </Styled.ContentArea>
      </Styled.ContentAreaWrapper>
    );
  }
);
