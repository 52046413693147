import {
  FloatingFocusManager,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React, { useEffect, useState, createContext, useMemo, useCallback } from 'react';
import { Body } from './elements/Body';
import { Footer } from './elements/Footer';
import { Header } from './elements/Header';
import { Styled } from './index.styles';
import { ModalProps } from './index.types';

export const ModalContext = createContext({
  isOpen: false,
  dismissable: true,
  open: () => {},
  close: () => {},
});

/**
 * A flexible modal dialog component built using the compound components pattern.
 * Provides a structured way to compose modal content using Modal.Header, Modal.Body,
 * and Modal.Footer components.
 * It uses the Floating UI library for positioning and interactions.
 * @param {React.ReactNode} children - The content of the modal.
 * @param {boolean} [dimmer=true] - Whether to show a dimmer behind the modal.
 * @param {boolean} [dismissable=true] - Whether the modal can be dismissed by clicking outside or pressing the escape key.
 * @param {object} [dismissOptions={}] - Additional options for the dismiss behavior.
 * @param {boolean} [legacy=false] - Whether to use legacy styles.
 * @param {string} [mountNodeId] - The ID of the DOM node where the modal should be mounted.
 * @param {Function} [onClose] - Callback function to be called when the modal is closed.
 * @param {Function} [onOpen] - Callback function to be called when the modal is opened.
 * @param {boolean} [open=false] - Whether the modal is initially open.
 * @param {ModalProps} props - Additional props to be passed to the modal.
 * @component
 * @example
 * ```tsx
 * <Modal open={isOpen} onClose={handleClose}>
 *   <Modal.Header>Title</Modal.Header>
 *   <Modal.Body>Content</Modal.Body>
 *   <Modal.Footer>Actions</Modal.Footer>
 * </Modal>
 * ```
 * @returns {JSX.Element} The rendered modal component.
 */
const ModalComponent = ({
  children,
  dimmer = true,
  dismissable = true,
  dismissOptions = {},
  legacy = false,
  mountNodeId,
  onClose,
  onOpen,
  open = false,
  ...props
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (newState) => switchOpenState(newState),
  });

  const role = useRole(context);
  const dismiss = useDismiss(context, {
    enabled: dismissable,
    ...dismissOptions,
  });

  const { getFloatingProps } = useInteractions([role, dismiss]);

  const handleClose = useCallback(() => {
    onClose && onClose();
    setIsOpen(false);
  }, [onClose]);

  const handleOpen = useCallback(() => {
    onOpen && onOpen();
    setIsOpen(true);
  }, [onOpen]);

  const switchOpenState = useCallback((newState) => {
    if (newState) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [handleClose, handleOpen]);

  // Sync external "open" prop with local "isOpen" state
  useEffect(() => {
    setIsOpen(open);
    open && onOpen && onOpen();
  }, [onOpen, open]);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      isOpen,
      dismissable,
      open: handleOpen,
      close: handleClose,
    }),
    [dismissable, handleClose, handleOpen, isOpen]
  );


  return (
    <>
      <FloatingPortal id={mountNodeId}>
        {isOpen && (
          <Styled.Overlay $dimmer={dimmer} $legacy={legacy} lockScroll>
            <FloatingFocusManager context={context}>
              <Styled.Container
                ref={refs.setFloating}
                {...getFloatingProps()}
                {...props}
              >
                <ModalContext.Provider value={contextValue}>
                  {children}
                </ModalContext.Provider>
              </Styled.Container>
            </FloatingFocusManager>
          </Styled.Overlay>
        )}
      </FloatingPortal>
    </>
  );
};

export const Modal = ModalComponent as typeof ModalComponent & {
  Body: typeof Body;
  Footer: typeof Footer;
  Header: typeof Header;
};

Modal.Body = Body;
Modal.Footer = Footer;
Modal.Header = Header;