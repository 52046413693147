import { Icon } from 'Components/shared/Icon';
import { UserPresence } from 'Components/shared/UserPresence';
import styled from 'styled-components';

const Content = styled.div`
  min-width: 490px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const ClearFieldButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 5px;
  translate: 5px -1px;
`;

const ClearFieldIcon = styled(Icon).attrs(() => ({
  icon: 'cancel-heavy',
  size: 'mini',
}))`
  color: ${({ theme }) => theme.colors.gray} !important;
  &:hover {
    color: ${({ theme }) => theme.colors.darkGray} !important;
  }
`;

const PresenceDot = styled(UserPresence)`
  position: static;
  display: inline-flex;
  width: 18px;
  height: 18px;
`;

export const Styled = {
  Content,
  ClearFieldIcon,
  ClearFieldButton,
  PresenceDot,
};
